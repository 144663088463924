import React, { Component } from 'react';
import './CSS/Home.css';

import Navbar from '../Components/Navbar';
import OlahQueTal from './OlahQueTal';
import Atuacao from './Atuacao';
import SomosAOlah from './SomosAOlah';
import TrazerEmocao from './TrazerEmocao';
import QuemJaDisseOlah from './QuemJaDisseOlah';

export class Home extends Component {
  render() {
    return (
      <div id='home' className='pageContent'>
        <Navbar />
        <OlahQueTal />
        <SomosAOlah />
        <TrazerEmocao />
        <Atuacao />
        <QuemJaDisseOlah />
      </div>
    )
  }
}

export default Home;