import React, { Component } from 'react';
import './CSS/QuemJaDisseOlah.css';

import Asa from '../Assets/Clients/asa.png';
import Azov from '../Assets/Clients/azov.png';
import BadenBaden from '../Assets/Clients/baden-baden.png';
import Ballantines from '../Assets/Clients/ballantines.jpg';
import BarraShopping from '../Assets/Clients/barra-shopping.jpg';
import Bugan from '../Assets/Clients/bugan.png';
import CBE from '../Assets/Clients/cbe.png';
import ChampagnePJ from '../Assets/Clients/champagne-pj.jpg';
import Chivas from '../Assets/Clients/chivas.png';
import Ekaut from '../Assets/Clients/ekaut.jpg';
import ForteNoronha from '../Assets/Clients/forte-noronha.png';
import Heineken from '../Assets/Clients/heineken.png';
import Ibis from '../Assets/Clients/ibis.png';
import Independente from '../Assets/Clients/independente.png';
import InstitutoME from '../Assets/Clients/instituto-me.jpg';
import Organomix from '../Assets/Clients/organomix.png';
import ParkShopping from '../Assets/Clients/park-shopping.jpg';
import PassaportScotch from '../Assets/Clients/passaport-scotch.png';
import Ramada from '../Assets/Clients/ramada.png';
import SaoBraz from '../Assets/Clients/sao-braz.png';
import ShoppingRecife from '../Assets/Clients/shopping-recife.png';
import Sky from '../Assets/Clients/sky.png';
import Tim from '../Assets/Clients/tim.png';
import Tuju from '../Assets/Clients/tuju.png';
import Vivo from '../Assets/Clients/vivo.png';

export class QuemJaDisseOlah extends Component {
  render() {
    return (
      <div id='qjdo' className='qjdoBG'>
        <div className='qjdoContent'>
            <div className='qjdoTitle'>QUEM JÁ DISSE OLAH</div>
            <ul className='qjdoImgs'>
                <li> <img src={Asa} alt='Asa' /> </li>
                <li> <img src={Azov} alt='Azov' /> </li>
                <li> <img src={BadenBaden} alt='Baden Baden' /> </li>
                <li> <img src={Ballantines} alt='Ballantines' /> </li>
                <li> <img src={BarraShopping} alt='Barra Shopping' /> </li>
                <li> <img src={Bugan} alt='Bugan' /> </li>
                <li> <img src={CBE} alt='Carneiros Beach Exclusive' /> </li>
                <li> <img src={ChampagnePJ} alt='Perrier Jouet' /> </li>
                <li> <img src={Chivas} alt='Chivas' /> </li>
                <li> <img src={Ekaut} alt='EKAUT' /> </li>
                <li> <img src={ForteNoronha} alt='Forte Noronha' /> </li>
                <li> <img src={Heineken} alt='Heineken' /> </li>
                <li> <img src={Ibis} alt='Ibis' /> </li>
                <li> <img src={Independente} alt='Farmácias Independente' /> </li>
                <li> <img src={InstitutoME} alt='Instituto ME' /> </li>
                <li> <img src={Organomix} alt='Organomix' /> </li>
                <li> <img src={ParkShopping} alt='Park Shopping' /> </li>
                <li> <img src={PassaportScotch} alt='Passaport Scotch' /> </li>
                <li> <img src={Ramada} alt='Ramada Hotel' /> </li>
                <li> <img src={SaoBraz} alt='São Braz' /> </li>
                <li> <img src={ShoppingRecife} alt='Shopping Recife' /> </li>
                <li> <img src={Sky} alt='Sky' /> </li>
                <li> <img src={Tim} alt='Tim' /> </li>
                <li> <img src={Tuju} alt='Tuju' /> </li>
                <li> <img src={Vivo} alt='Vivo' /> </li>
            </ul>
        </div>
      </div>
    )
  }
}

export default QuemJaDisseOlah;