import React, { Component } from 'react';
import './CSS/SomosAOlah.css';

import Asa from '../Assets/asa.png';

export class SomosAOlah extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       saoAnimated: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.doAnimation);
  }

  removeEvent = () => {
    window.removeEventListener('scroll', this.doAnimation);
  }

  doAnimation = () => {
    if (!this.state.saoAnimated) {
      if (document.documentElement.scrollTop >= 460) {
        
        const wingHolder = document.getElementById('wing-holder');
        wingHolder.style.animation = `wing 0.5s ease forwards`;

        const textHolder = document.getElementById('sao-text-holder');
        textHolder.style.animation = `textAnim 0.5s ease forwards 0.3s`;

        this.removeEvent();

        this.setState({ saoAnimated: true });
      }
    }
  }

  render() {
    return (
    <div id='sao' className='saoBG saoContent'>
      <div id='wing-holder' className='saoImgHolder'> <img className='asa' src={Asa} alt='Asa' /> </div>
      <div id='sao-text-holder' className='saoTextHolder'>
          <p className='saoTitle'>SOMOS A OLAH.</p>
          <p className='saoBody'>
              Desenvolvemos conteúdos com arte e conceitos. Acreditamos no livre pensamento.
              Àquele que nos tira do lugar comum, que nos move à realizar fora do óbvio.
              Observar o mundo é a nossa maior fonte de riqueza. Nosso maior referencial.
              Os nossos olhos não têm fronteiras, as nossas ideias também não.
          </p>
          <p className='saoFinal'>
              Pensar é a arte que nos dá asas para voar.
          </p>
      </div>
    </div>
    )
  }
}

export default SomosAOlah;