import React, { Component } from 'react';
import './CSS/Atuacao.css';

import Cerebro from '../Assets/cerebro.png';

export class Atuacao extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       aAnimated: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.doAnimation);
  }

  removeEvent = () => {
    window.removeEventListener('scroll', this.doAnimation);
  }

  doAnimation = () => {
    if (!this.state.aAnimated) {
      if (document.documentElement.scrollTop >= 2360) {
        
        const brainHolder = document.getElementById('brain-holder');
        brainHolder.style.animation = `brain 0.5s ease forwards`;
        
        const aTitle = document.getElementById('a-title');
        aTitle.style.animation = `aItemsAnim 0.5s ease forwards`;

        const aItems = document.querySelectorAll('#a-items>div');
        aItems.forEach((item, index) => {
            if (item.style.animation) {
                item.style.animation = '';
            } else {
                item.style.animation = `aItemsAnim 0.5s ease forwards ${index / 14 + 0.2}s`;
            }
        });

        this.removeEvent();

        this.setState({ aAnimated: true });
      }
    }
  }

  render() {
    return (
      <div id='atuacao' className='aBG aContent'>
        <div id='brain-holder' className='aImgHolder'> <img className='cerebro' src={Cerebro} alt='Cérebro' /> </div>
        <div className='aTextHolder'>
            <div id='a-title' className='aTitle'>ATUAÇÃO</div>
            <div id='a-items' className='aBody'>
              <div>Planejamento e diagnóstico de imagem/ marca</div>
              <div>Definição de persona e slogan</div>
              <div>Planejamento de mídia Institucional e Promocional</div>
              <div>Criação e produção de vídeos para tv e spot / jingle rádio</div>
              <div>Criação de layouts para todos os fins</div>
              <div>Criação e execução de ações promocionais</div>
              <div>Desenvolvimento de projetos especiais</div>
              <div>Desenvolvimento de sinalização para ponto de venda</div>
              <div>Criação e produção gráfica como encartes, folhetos, folders, etc</div>
              <div><b>Gestão Completa de Mídias Sociais</b></div>
              <div>Tráfego estratégico ADS</div>
              <div>Criação e programação de sites</div>
              <div>Desenvolvimento de projetos especiais digitais</div>
              <div>Desenvolvimento de APPs</div>
            </div>
        </div>
      </div>
    )
  }
}

export default Atuacao;