import React, { Component } from 'react';
import './CSS/OlahQueTal.css';

import LogoOlah from '../Assets/logo-olah.png';

export class OlahQueTal extends Component {
  render() {
    return (
      <div id='oqt' className='oqtBG'>
          <div className='logoContainer'> <img src={LogoOlah} alt='OLAH' /> </div>
      </div>
    )
  }
}

export default OlahQueTal;