import React, { Component } from 'react';
import './CSS/Navbar.css';

// eslint-disable-next-line
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

export class Navbar extends Component {

  constructor(props) {
    super(props)

    this.state = {
        openBM: false,
        yellowBar: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.colorHandler);
  }

  colorHandler = () => {
    const navHolder = document.getElementById('nh');
    const burgerHolder = document.getElementById('bh');

    let scTop = document.documentElement.scrollTop;
    let sectionHeight = document.documentElement.scrollHeight / 5; // totalHeigh / pageCount

    if (this.state.yellowBar) {
      if ((scTop >= sectionHeight*0 && scTop < sectionHeight*1) || (scTop >= sectionHeight*2 && scTop < sectionHeight*3) || scTop >= sectionHeight*4) {
        navHolder.style.background = `var(--s-color)`;
        burgerHolder.style.background = `var(--s-color)`;

        const navLinks = document.querySelectorAll('.navbar>a');
        navLinks.forEach(link => {
          link.classList.remove('yellow');
        });

        this.setState({ yellowBar: false });
      }
    } else {
      if ((scTop >= sectionHeight*1 && scTop < sectionHeight*2) || (scTop >= sectionHeight*3 && scTop < sectionHeight*4)) {
        navHolder.style.background = `var(--t-color)`;
        burgerHolder.style.background = `var(--t-color)`;
  
        const navLinks = document.querySelectorAll('.navbar>a');
        navLinks.forEach(link => {
          link.classList.add('yellow');
        });

        this.setState({ yellowBar: true });
      }
    }
  }

  setBurgerOpen = () => {
    const menuHolder = document.getElementById('nh');
    const menu = document.getElementById('nl');
    const burgerLines = document.querySelectorAll('.burger div');

    const navLinks = document.querySelectorAll('.navbar>a');

    navLinks.forEach((link, index) => {
        if (link.style.animation) {
            link.style.animation = '';
        } else {
            link.style.animation = `navLinkFade 0.5s ease forwards ${index / 4 + 0.2}s`;
        }
    });

    if (!this.state.openBM) {
        menuHolder.classList.add('open');
        menu.style.display = 'flex';
        menu.classList.add('open');

        burgerLines.forEach(line => {
            line.classList.add('open');
        });
        
        this.setState({
            openBM: true
        });
    } else {
        menuHolder.classList.remove('open');
        menu.style.display = 'none';
        menu.classList.remove('open');

        burgerLines.forEach(line => {
            line.classList.remove('open');
        });
        
        this.setState({
            openBM: false
        });
    }
  }

  goToSection = (secId) => {
    scroller.scrollTo(secId, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }

  // <a href='#capolavori' onClick={() => this.goToSection('capolavori')}>CAPOLAVORI</a>
  render() {
    return (
      <div id='nh' className='navbarHolder'>
        <div id='bh' className='burgerHolder'>
          <div className='burger' onClick={this.setBurgerOpen}>
            <div className='b-l1' />
            <div className='b-l2' />
            <div className='b-l3' />
          </div>
        </div>

        <div id='nl' className='navbar'>
          <a href='#oqt' onClick={() => this.goToSection('oqt')}>OLAH, ¿QUÉ TAL?</a>
          <a href='#atuacao' onClick={() => this.goToSection('atuacao')}>ATUAÇÃO</a>
          <a href='#qjdo' onClick={() => this.goToSection('qjdo')}>QUEM JÁ DISSE OLAH</a>
        </div>
      </div>
    )
  }
}

export default Navbar;