import React, { Component } from 'react';
import './CSS/TrazerEmocao.css';

import Coracao from '../Assets/coracao.png';
import TituloTE from '../Assets/trazer-emocao.png';
import TituloTEMobile from '../Assets/trazer-emocao-mobile.png';

export class TrazerEmocao extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         title: TituloTE
      }
    }

    componentDidMount() {
        if (window.innerWidth > 800) {
            this.setState({ title: TituloTE });
        } else {
            this.setState({ title: TituloTEMobile });
        }
    }

    render() {
        return (
            <div className='teBG teContent'>
                <div className='teImgHolder'> <img className='coracao' src={Coracao} alt='Coração' /> </div>
                <div className='teTextHolder'>
                    <div className='teTitleHolder'> <img className='teTitle' src={this.state.title} alt='Título' /> </div>
                    <div className='teBody'>
                        Trabalhamos com todas as ferramentas e tecnologias disponíveis para atingirmos os KPI´s de cada cliente.
                        Mas, atuamos sempre com a observação da emoção –
                        porque ela é o que move as pessoas e através dela podemos nos conectar com cada um.
                    </div>
                </div>
            </div>
        )
    }
}

export default TrazerEmocao;